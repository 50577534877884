// Skills Icons
import rubyOnRails from "./images/rubyOnRails.svg"
import reactjs from "./images/reactjs.svg"
import jsIcon from "./images/js.svg"
import reactIcon from "./images/react.svg"
import herokuIcon from "./images/heroku.svg"
import linkedinIcon from "./images/linkedin.svg"
import mediumIcon from "./images/medium.svg"
import designIcon from "./images/design.svg"
import codeIcon from "./images/code.svg"

// Social Icon
import githubIcon from "./images/github.svg"
import codepenIcon from "./images/codepen.svg"

// Images
import mober from "./images/moberwork.jpg"
import ewtnflix from "./images/ewtnflix.jpg"
import xpensio from "./images/xpensio.jpg"
import oceancoast from "./images/oceancoast.jpg"
import ocexpress from "./images/ocexpress.jpg"
import truhealth from "./images/truhealth.jpg"

export default {
  //   Header Details ---------------------
  name: "Mo",
  headerTagline: [
    //Line 1 For Header
    "Building better web,",
    //Line 2 For Header
    "mobile apps,",
    //Line 3 For Header
    "and solutions",
  ],
  //   Header Paragraph
  headerParagraph: null,

  //Contact Email
  contactEmail: "hi@moseslucas.com",

  // End Header Details -----------------------

  // Work Section ------------------------
  projects: [
    {
      title: "Tru Health Diagnostics", //Project Title - Add Your Project Title Here
      para: "Molecular Lab System", // Add Your Service Type Here
      //Project Image - Add Your Project Image Here
      imageSrc: truhealth,
      //Project URL - Add Your Project Url Here
      url: "#",
    },
    {
      title: "OC Express PH", //Project Title - Add Your Project Title Here
      para: "Website & Cargo / Courier Management System",
      //Project Image - Add Your Project Image Here
      imageSrc: ocexpress,
      //Project URL - Add Your Project Url Here
      url: "https://ocexpress.ph/",
    },
    {
      title: "Ocean Coast", //Project Title - Add Your Project Title Here
      para: "Website, Android, Purchasing & Inventory System",
      //Project Image - Add Your Project Image Here
      imageSrc: oceancoast,
      //Project URL - Add Your Project Url Here
      url: "https://oceancoastshippingcorp.com/",
    },
    {
      title: "EWTN flix", //Project Title - Add Your Project Title Here
      para: "Android, iOS & Admin",
      //Project Image - Add Your Project Image Here
      imageSrc: ewtnflix,
      //Project URL - Add Your Project Url Here
      url: "https://apps.apple.com/us/app/ewtn-flix/id1581063502#?platform=iphone",
    },
    {
      title: "Xpensio", //Project Title - Add Your Project Title Here
      para: "Android & iOS",
      //Project Image - Add Your Project Image Here
      imageSrc: xpensio,
      //Project URL - Add Your Project Url Here
      url: "https://play.google.com/store/apps/details?id=io.xpens",
    },
    {
      title: "Mober", //Project Title - Add Your Project Title Here
      para: "Android, iOS & API",
      //Project Image - Add Your Project Image Here
      imageSrc: mober,
      //Project URL - Add Your Project Url Here
      url: "https://www.mober.ph/",
    },
    /*
    If You Want To Add More Project just Copy and Paste This At The End
    ,{
        title: 'Project Five',
        para: 'Something Amazing',
        imageSrc: "",
        url: ''
    }
    */
  ],

  // End Work Section -----------------------

  // About Secton --------------
  aboutParaOne:
    "Hi, I'm Moses Lucas - people call me 'Mo'. I am a full-stack developer helping make the world a better place through building web and mobile apps.",
  aboutParaTwo:
    "I've built and deployed many projects in the past that mainly uses Ruby on Rails, React & React Native where my strength is at.",
  aboutParaThree:
    "I write helpful articles on medium and write songs with my ES-335 on soundcloud. I am also a board of director of Tourism Mountain Ocean Corporation - a company that boosts and uplifts the tourism here in Metro REINA (Real, Infanta, Nakar), Quezon Philippines.",
  aboutImage: "https://i.ibb.co/wQ2qYFY/Mo.jpg",

  //   End About Section ---------------------

  // Skills Section ---------------

  //   Import Icons from the top and link it here

  skills: [
    {
      img: rubyOnRails,
      title: "Ruby on Rails",
      para: "I've been using RoR since 2017 for building web applications. A tech that is tested, proven and fast for development.",
    },
    {
      img: reactjs,
      title: "React JS",
      para: "Making client needs for a beautiful UX possible. React enables me to build flexible UI components.",
    },
    {
      img: reactIcon,
      title: "React Native",
      para: "Building Android & iOS mobile apps fast and fun from start to end.",
    },
    {
      img: jsIcon,
      title: "Javascript ES6+",
      para: "Rails, React and React Native, all of these tech uses Javascript ES6+",
    },
    {
      img: herokuIcon,
      title: "Heroku",
      para: "Deploy, maintain and optimize Rails app to Heroku servers.",
    },
    {
      img: githubIcon,
      title: "Git",
      para: "We use GIT for collaborative development and CI/CD (Continues Integration & Delivery).",
    },
  ],

  // End Skills Section --------------------------

  //   Promotion Section --------------------------

  promotionHeading: null,
  promotionPara: null,
  // End Promotion Section -----------------

  //   Contact Section --------------

  contactSubHeading: "Let's create your next product together",
  social: [
    // Add Or Remove The Link Accordingly
    { img: githubIcon, url: "https://github.com/moseslucas" },
    // {
    //   img: linkedinIcon,
    //   url: "https://www.linkedin.com/in/moseslucas/",
    // },
    {
      img: mediumIcon,
      url: "https://medium.com/@lukeses09/",
    },
  ],

  // End Contact Section ---------------
}

// Thanks for using this template, I would love to hear from you contact me at hello@chetanverma.com
